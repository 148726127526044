import React from 'react';
import classNames from 'classnames';
import { useTheme } from '@components/theme';
import breakpoints from '@constants/breakpoints';
import PrismicImage from '@components/shared/images/atoms/Image/PrismicImage';
import { IPrismicImage } from '@lib/prismic/types';

import LottieAnimation from '@components/shared/LottieAnimation/LottieAnimation';
import { getResizedImageUrl } from '@lib/image';
import styles from './OurPartners.module.scss';

interface IOurPartnersProps {
    eyebrow: string;
    image: IPrismicImage;
    title: string;
    newHomepage: boolean;
}

export default function OurPartners({
  eyebrow,
  image,
  title,
  newHomepage,
}: IOurPartnersProps) {
  const theme = useTheme();
  const lottieDesktop = 'https://lottie.host/1f243407-8aee-4f54-b95a-5fee76970ab0/xtBAJlS4LI.json';
  const lottieMobile = 'https://lottie.host/043a7650-13f5-4ff9-9636-91a1c819b360/unqVKhcUp4.json';
  const ourPartnerImageDesktopUrl = 'https://static.havenly.com/home/our_partners.jpg';
  const ourPartnerImageMobile = getResizedImageUrl({
    height: 600,
    width: 600,
    fit: 'fit-in',
    url: 'https://static.havenly.com/home/our_partners.jpg',
    quality: 80,
    valign: 'middle',
    halign: 'center'
  });

  const imageSection = newHomepage ? (
    <picture>
      <source
        srcSet={getResizedImageUrl({
          url: ourPartnerImageDesktopUrl,
          valign: 'bottom',
          width: 1020,
          height: 600,
        })}
        media={'(min-width: 1800px)'}
      />
      <source
        srcSet={getResizedImageUrl({
          url: ourPartnerImageDesktopUrl,
          valign: 'bottom',
          width: 820,
          height: 600,
        })}
        media={'(min-width: 1600px)'}
      />
      <source
        srcSet={getResizedImageUrl({
          url: ourPartnerImageDesktopUrl,
        })}
        media={'(min-width: 1024px)'}
      />
      <source
        srcSet={getResponsiveImages(
          ourPartnerImageDesktopUrl,
          421,
          565,
          'jpg'
        )}
        media={'(min-width: 768px)'}
      />
      <source
        srcSet={getResponsiveImages(
          ourPartnerImageDesktopUrl,
          600,
          600,
          'jpg'
        )}
        media={'(min-width: 600px)'}
      />
      <img src={ourPartnerImageMobile} alt="Our Partners" />
    </picture>
  ) : (
    <PrismicImage
      className={styles.OurPartnerImage}
      image={image}
      breakpoints={{
        mobile: breakpoints.tablet,
        tablet: breakpoints['large-desktop'],
      }}
    />
  );

  return (
    <section className={classNames(styles.OurPartnersContainer, {
      [styles.NewHomePage]: newHomepage,
    })}
    >
      {imageSection}
      <div className={styles.OurPartnersContentContainer}>
        <div className={styles.OurPartnersContent}>
          <p className={classNames(theme.styles.Overline, styles.Overline)}>{eyebrow}</p>
          <p className={classNames(theme.styles.Body, styles.OurPartnerBody) }>{title}</p>
          <LottieAnimation
            className={styles.PartnerList}
            width={'100%'}
            breakpoint="tablet"
            desktop={{
              src: lottieDesktop
            }}
            mobile={{
              width: '100%',
              height: '100%',
              src: lottieMobile
            }}
          />
        </div>
      </div>
    </section>
  );
}

function getResponsiveImages(
  image: string | undefined,
  width: number,
  height: number,
  format: string
) {
  if (!image) return '';
  const main = [`${getThumborURL(image, width, height, format)} ${width}w`];
  main.push(`${getThumborURL(image, width * 2, height * 2, format)} 2x`);

  return main.join(', ');
}

function getThumborURL(
  url: string | undefined,
  width: number,
  height: number,
  format: string
): string {
  if (!url) return '';
  return getResizedImageUrl({
    format,
    quality: 90,
    url,
    width,
    height,
  });
}
